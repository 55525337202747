import React from 'react';
import { FormattedMessage } from 'react-intl';

import { uniqueId } from 'helpers/uniqueId';
import { PriceTier, RoomPrice } from 'types/entities';
import { AppSchema } from 'stores/RootStore';

import style from './RoomInfoModal.module.scss';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

interface Props {
  priceTiers: RoomPrice;
  schema: AppSchema;
}

const PriceTiers = ({ priceTiers, schema }: Props) => {
  const renderPriceTier = (tier: PriceTier, index: number) => {
    if (schema === 'ccsc') {
      return <FormattedCurrency value={tier.price} />;
    }

    return (
      <>
        {tier.maxDurationMins ? (
          tier.price !== priceTiers.tiers[index + 1]?.price &&
          (tier.maxDurationMins === 240 ? (
            <>
              &#x00BD; &nbsp;
              <FormattedMessage
                id="modal.roomInfo.cost.half.up.to"
                values={{
                  hours: tier.maxDurationMins / 60,
                  cost: <FormattedCurrency value={tier.price} />,
                }}
                tagName={React.Fragment}
              />
            </>
          ) : (
            <FormattedMessage
              id="modal.roomInfo.cost.up.to"
              values={{
                hours: tier.maxDurationMins / 60,
                cost: <FormattedCurrency value={tier.price} />,
              }}
              tagName={React.Fragment}
            />
          ))
        ) : tier.price === 0 ? (
          <FormattedCurrency value={tier.price} />
        ) : (
          <FormattedMessage
            id="modal.roomInfo.capactiery.more.than"
            values={{
              hours: priceTiers.tiers[index - 1].maxDurationMins / 60,
              cost: <FormattedCurrency value={tier.price} />,
            }}
          />
        )}
      </>
    );
  };

  return (
    <ul className={style.info__value}>
      {priceTiers.tiers.map((it, index) => {
        return (
          <li key={uniqueId()} className={style.cost__value}>
            {renderPriceTier(it, index)}
          </li>
        );
      })}
    </ul>
  );
};

export default PriceTiers;
